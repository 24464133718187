<template>
  <div id="addVehicleDialog">
    <el-dialog
      title="新增商户"
      :visible.sync="show"
      :close-on-press-escape="false"
      @closed="reset"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="145px"
      >
        <el-form-item label="商户名称" prop="shopName">
          <el-input
            v-model="addForm.shopName"
            placeholder="商户名称"
          >
            <el-select class="shopName_province" v-model="shopName_province" slot="prepend" placeholder="省份" clearable>
              <el-option label="浙江" value="浙江"></el-option>
            </el-select>
            <el-select class="shopName_province shopName_city" filterable v-model="shopName_city" slot="prepend" placeholder="城市" clearable>
              <el-option :label="item.label" :value="item.value" v-for="item of select_ZheJiang_city" :key="select_ZheJiang_city.value"></el-option>
            </el-select>
            <el-select class="shopName_type" v-model="shopName_type" slot="append" placeholder="企业类型" clearable>
              <el-option label="有限责任公司" value="有限责任公司"></el-option>
              <el-option label="股份有限公司" value="股份有限公司"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item label="商户位置" prop="shopAddress">
          <el-input
            v-model="addForm.shopAddress"
            placeholder="请输入商户位置"
          ></el-input>
        </el-form-item>
        <el-form-item label="商户联系人" prop="contactName">
          <el-input
            v-model="addForm.contactName"
            placeholder="请输入商户联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人手机号" prop="contactPhone">
          <el-input
            v-model="addForm.contactPhone"
            placeholder="请输入联系人手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="登录名" prop="username">
          <el-input
            v-model="addForm.username"
            placeholder="请输入登录名"
          ></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="password">
          <el-input
            type="password"
            v-model="addForm.password"
            placeholder="请输入登录密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="按月发放金额" prop="ticketPerMonth">
          <el-input
              v-model.number="addForm.ticketPerMonth"
              placeholder="请输入按月发放金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="大众/美团人均消费">
          <el-input
            v-model="addForm.averageConsumption"
            placeholder="请输入大众/美团人均消费"
          ></el-input>
        </el-form-item>
        <el-form-item label="租金付款方式" prop="rentFree">
          <el-radio v-model="addForm.rentFree" label="是">免租</el-radio>
          <el-radio v-model="addForm.rentFree" label="否">未免租</el-radio>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="2"
            v-model="addForm.shopRemarks"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {select_ZheJiang_city} from "@/utils/selectData"
export default {
  props: ["getList"],
  data() {
    return {
      show: false,
      loading: false,
      addForm: {
        shopName: "",
        shopAddress: "",
        contactName: "",
        contactPhone: "",
        username: "",
        password: "",
        ticketPerMonth:"",
        averageConsumption: "",
        rentFree: "",
        shopRemarks: "",
      },
      shopName_province: "",
      shopName_city: "",
      shopName_type: "",
      addFormRules: {
        shopName: [
          { required: true, message: "请输入商户名称", trigger: "blur" },
        ],
        shopAddress: [
          { required: true, message: "请输入商户位置", trigger: "blur" },
        ],
        contactName: [
          { required: true, message: "请输入商户联系人", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入联系人手机号", trigger: "blur" },
        ],
        username: [
          { required: true, message: "请输入登录名", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
        ],
        ticketPerMonth: [
          { required: true, message: "请输入登录名", trigger: "blur" },
        ],
        rentFree: [
          { required: true, message: "请选择免租方式", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.$set(this, "addForm", {
        shopName: "",
        shopAddress: "",
        contactName: "",
        contactPhone: "",
        username: "",
        password: "",
        ticketPerMonth:"",
        averageConsumption: "",
        rentFree: "",
        shopRemarks: "",
      });
      this.shopName_province=""
      this.shopName_city=""
      this.shopName_type=""
      this.$refs["addForm"].resetFields();
    },
    async confirmBtn() {
      let falg = true;
      this.$refs["addForm"].validate((valid) => {
        if (!valid) {
          falg = false;
        }
      });
      if (!falg) {
        return;
      }
      try {
        this.loading = true;
        let res = await this.$http.post("/parking/shop/add", {
          ...this.addForm,
          shopName:this.shopName_province+this.shopName_city+this.addForm.shopName+this.shopName_type,
          parkingLotId: this.$route.params.id,
        });
        if (res.code === 0) {
          this.getList();
          this.$message.success("添加成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#addVehicleDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 600px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .shopName_province,
        .shopName_type{
          width: 80px;
          .el-input__inner,{
            border: none;
          }
        }
        .shopName_city{
          width: 90px;
          margin-left: 20px;
        }
        .shopName_type{
          width: 126px;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
